<template>
  <article>
    <RouterNav :name="name + '的个人报告'"></RouterNav>
    <div class="user-report">

      <section class="section">
        <div class="title">
          <div class="left">作业数据统计</div>
        </div>
        <div class="statistics">
          <div class="statistics-item">
            <div class="item-title">作业</div>
            <div class="item-num">{{ arrangeSum }}</div>
          </div>
          <div class="statistics-item">
            <div class="item-title">完成度</div>
            <div class="item-num">{{ finish }}%</div>
          </div>
          <div class="statistics-item">
            <div class="item-title">得分率</div>
            <div class="item-num">{{ scoreRate }}%</div>
          </div>
        </div>
      </section>
      <section class="work-list" v-infinite-scroll="load" :infinite-scroll-disabled="nomore" infinite-scroll-immediate="true">
        <el-table :data="homeworkList" ref="table" border header-cell-class-name="table-header-cell" cell-class-name="table-cell">
          <el-table-column label="作业名称" prop="homeworkName" align="left" width="444"/>
          <el-table-column label="完成度" prop="scoreRate" align="center">
            <template slot-scope="props">
              <div>{{ props.row.finish && props.row.finish !== 0 ? props.row.finish + '%' :'-' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="得分率" sortable sort-by="scoreRate" align="center">
            <template slot-scope="props">
              <div
                v-if="props.row.scoreRate !== null && props.row.scoreRate >= 0"
                :class="props.row.scoreRate > 85 ? 'col-1DC060' : props.row.scoreRate > 60 ? 'col-fed942' : 'col-FF3C30'">
                {{   props.row.scoreRate  }}%
              </div>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button @click="workDetail(scope.row)" type="text" style="font-size: 15px">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </section>
    </div>
  </article>
</template>

<script>
import RouterNav from '@/components/RouterNav'

export default {
  name: 'userReport',
  components: {
    RouterNav,
  },
  data() {
    return {
      name: '',
      arrangeSum: 0,
      finish: 0,
      scoreRate: 0,
      homeworkList: [],
      page: 1,
      pageSize: 10,
      nomore: false,
      startTime:'',
      endTime:'',
      studentId:'',
      classId:'',
      suspendLoad: false,
      timeID: ''
    }
  },
  mounted() {
    document.getElementById('app').scrollTop = 0
    const {query} = this.$route
    this.id = query.id
    this.classId = query.classId
    this.startTime = query.startTime
    this.endTime = query.endTime
    this.dateType = query.dateType
    this.getPersonalReport()
  },
  methods: {
    getPersonalReport() {
      const {dateType} = this
      const dataParam = {
        studentId: this.id,
        classId: this.classId,
        startTime: this.startTime,
        endTime: this.endTime,
        dateType
      }
      this.$axios({
        method: 'post',
        url: this.$urls.getPersonalReport,
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataParam
      })
      .then((response) => {
        if (response.state === '11') {
          const {data} = response
          this.name = data.studentName
          this.arrangeSum = data.arrangeSum
          this.finish = data.finish
          this.scoreRate = data.scoreRate
          this.getPersonalHomeworkList()
        }
      })
    },
    getMore() {
      this.getPersonalHomeworkList(this.page + 1)
    },
    getPersonalHomeworkList(page) {
      this.suspendLoad = true
      const {dateType} = this
      const dataParam = {
        studentId: this.id,
        classId: this.classId,
        startTime: this.startTime,
        endTime: this.endTime,
        dateType
      }
      dataParam.pageSize = this.pageSize
      dataParam.page = page || this.page
      this.$axios({
        method: 'post',
        url: this.$urls.getPersonalHomeworkList,
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataParam
      })
      .then((response) => {
        setTimeout(() => {
          this.suspendLoad = false
        }, 500)
        if (response.state === '11') {
          this.nomore = response.data.pageContents.length < this.pageSize
          const { homeworkList } = this
          this.homeworkList = dataParam.page > 1 ? [...homeworkList, ...response.data.pageContents] : response.data.pageContents
          if (page) this.page = page
        }
      }).catch(() => {
        setTimeout(() => {
          this.suspendLoad = false
        }, 500)
      })
    },
    listShowAllChange(attr) {
      this[attr] = !this[attr]
    },
    workDetail(item) {
      if (item.taskType === 3) {
        this.$confirm('该内容Web版未适配，请前往APP查看', '提示', {
          type: 'warning'
        }).then(() => {})
        return
      }
      this.$router.push({
        name: 'workDetail',
        query: {
          itemId: item.id,
          name: item.homeworkName,
          clazzId: this.classId,
          passageType: item.passageType
        }
      })
    },
    load () {
      if (this.nomore || this.suspendLoad) return
      const ms = 500
      clearTimeout(this.timeID)
      this.timeID = setTimeout(() => {
        this.getPersonalHomeworkList(this.page + 1)
      }, ms)
    }
  }
}
</script>

<style scoped lang="scss">

.user-report {
  height: calc(100vh - 144px);
  background-color: #FFFFFF;
  padding: 37px 28px 0 28px;
}

.section{
  background-color: #fff;
  margin-bottom: 20px;
  overflow: hidden;

  .title {
    background: #F6F6F6;
    border: 1px solid #E5E5E5;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      font-size: 17px;
      font-weight: bold;
      color: #333333;
      line-height: 24px;
      padding: 15px 0 15px 28px;
    }
  }
  .statistics {
    display: flex;
    justify-content: space-around;
    align-items: center;


    .statistics-item {
      border: 1px solid #E5E5E5;
      border-top-color: transparent;
      height: 120px;
      width: 100%;
      text-align: center;

      &:nth-child(1),
      &:nth-child(2), {
        border-right-color: transparent;
      }

      .item-title {
        font-size: 17px;
        font-weight: 400;
        color: #999999;
        padding-top: 21px;
        display: flex;
        justify-content: center;
        align-items: center;

      }

      .item-num {
        padding-top: 12px;
        font-size: 30px;
        font-weight: bold;
        color: #333333;
      }
    }
  }
}

.work-list{
  height: calc(100vh - 405px);
  //height: 400px;
  overflow: auto;
  .el-table {

    ::v-deep .table-header-cell {
      font-size: 15px;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
      height: 54px;
      background: #F6F6F6;

      &:first-child {
        border-left: 1px solid #EBEEF5;
      }
    }

    ::v-deep .table-cell {
      .cell {
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
      }
    }

    ::v-deep .el-table__row td:first-child {
      border-left: 1px solid #EBEEF5;
    }

    ::v-deep .el-table__body tr:hover > td {
      background-color: #F6F6F6 !important;
    }

    ::v-deep th.ascending {
      .sort-caret.ascending {
        border-bottom-color: #333333;
      }
    }

    ::v-deep th.descending {
      .sort-caret.descending {
        border-top-color: #333333;
      }
    }

  }
}

</style>
